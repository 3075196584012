import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: ${props => props.theme.spacing[4]} 0;

  @media (min-width: ${props => props.theme.screens.sm}) {
    max-width: ${props => props.theme.screens.sm};
  }

  @media (min-width: ${props => props.theme.screens.md}) {
    max-width: ${props => props.theme.screens.md};
  }

  @media (min-width: ${props => props.theme.screens.lg}) {
    max-width: ${props => props.theme.screens.lg};
  }

  @media (min-width: ${props => props.theme.screens.xl}) {
    max-width: ${props => props.theme.screens.xl};
  }
`

export default Container
