import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import theme from '../styles/theme'
import Container from '../components/styles/Container'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <main>{children}</main>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
