import React from 'react'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>Oh, hello there.</h1>
    <p>Welcome to Chad's site.</p>
  </Layout>
)

export default IndexPage
